/**
 * Modfied version of [termynal.js](https://github.com/ines/termynal/blob/master/termynal.css).
 *
 * @author Ines Montani <ines@ines.io>
 * @version 0.0.1
 * @license MIT
 */
 .react-terminal-wrapper {
  width: 100%;
  background: #252a33;
  color: #eee;
  font-size: 18px;
  font-family: 'Fira Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace;
  border-radius: 4px;
  padding: 75px 45px 35px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
 }

.react-terminal {
  height: 73vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.react-terminal-wrapper.react-terminal-light {
  background: #ddd;
  color: #1a1e24;
}

/* .react-terminal-wrapper:before {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;

  background: #d9515d;
  -webkit-box-shadow: 25px 0 0 #f4c025, 50px 0 0 #3ec930;
          box-shadow: 25px 0 0 #f4c025, 50px 0 0 #3ec930;
} */

.react-terminal-wrapper:after {
  content: attr(data-terminal-name);
  position: absolute;
  color: #a2a2a2;
  top: 5px;
  left: 0;
  width: 100%;
  text-align: center;
}

.react-terminal-wrapper.react-terminal-light:after {
  color: #D76D77;
}

.react-terminal-line {
  display: block;
  line-height: 1.5;
}

.react-terminal-line:before {
  /* Set up defaults and ensure empty lines are displayed. */
  content: '';
  display: inline-block;
  vertical-align: middle;
  color: #a2a2a2;
}

.react-terminal-light .react-terminal-line:before {
  color: #D76D77;
}

.react-terminal-input:before {
  margin-right: 0.75em;
  content: '$';
}

.react-terminal-input[data-terminal-prompt]:before {
  content: attr(data-terminal-prompt);
}

.react-terminal-active-input {
  /* content: '▋'; */
  content: '|';
  font-family: monospace;
  margin-left: 0px;
  margin-right: 0px;
  opacity: 0.6;
}

.react-terminal-wrapper:focus-within .react-terminal-active-input:after {
  /* content: '▋'; */
  content: '|';
  font-family: monospace;
  margin-left: -4px;
  margin-right: -4px;
  /* -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite;
  opacity: 1; */
}

/* Cursor animation */

@-webkit-keyframes blink {
  50% {
      opacity: 0;
  }
}

@keyframes blink {
  50% {
      opacity: 0;
  }
}

.terminal-hidden-input {
    position: fixed;
    left: -1000px;
}

pre {
  margin: 0;
}

/* .react-terminal-progress {
  display: flex;
  margin: .5rem 0;
}
.react-terminal-progress-bar {
  background-color: #fff;
  border-radius: .25rem;
  width: 25%;
}
.react-terminal-wrapper.react-terminal-light .react-terminal-progress-bar {
  background-color: #000;
} */